<template>
  <b-container fluid>
    <b-row style="margin-top: 18px;">
      <b-col md="3" sm="6">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h6>{{ $t("dashboard1.card1") }}</h6>
              <!-- <span class="iq-icon"><i class="ri-information-fill"></i></span> -->
            </div>
            <div
              class="
                iq-customer-box
                d-flex
                align-items-center
                justify-content-between
                mt-3
              "
            >
              <div class="d-flex align-items-center">
                <div class="rounded-circle iq-card-icon iq-bg-primary mr-2">
                  <!-- <i class="ri-inbox-fill" style="font-size: 28px;"></i> -->
                  <img src="@/assets/images/home/tj-icon-1.png" alt="">
                </div>
                <h2>{{ myInfo.assignment.assignmentCount }}</h2>
              </div>
              <div class="iq-map text-primary font-size-32">
                <i class="ri-bar-chart-grouped-line"></i>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3" sm="6">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h6>{{ $t("dashboard1.card2") }}</h6>
              <!-- <span class="iq-icon"><i class="ri-information-fill"></i></span> -->
            </div>
            <div
              class="
                iq-customer-box
                d-flex
                align-items-center
                justify-content-between
                mt-3
              "
            >
              <div class="d-flex align-items-center">
                <div class="rounded-circle iq-card-icon iq-bg-danger mr-2">
                  <!-- <i class="ri-radar-line"></i> -->
                  <!-- <i class="ri-file-list-2-line"></i> -->
                  <img src="@/assets/images/home/tj-icon-2.png" alt="">
                </div>
                <h2>{{ myInfo.assignment.waitStartCount }}</h2>
              </div>
              <div class="iq-map text-danger font-size-32">
                <i class="ri-bar-chart-grouped-line"></i>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3" sm="6">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h6>{{ $t("dashboard1.card3") }}</h6>
              <!-- <span class="iq-icon"><i class="ri-information-fill"></i></span> -->
            </div>
            <div
              class="
                iq-customer-box
                d-flex
                align-items-center
                justify-content-between
                mt-3
              "
            >
              <div class="d-flex align-items-center">
                <div class="rounded-circle iq-card-icon iq-bg-warning mr-2">
                  <!-- <i class="ri-price-tag-3-line"></i> -->
                  <!-- <i class="ri-group-fill"></i> -->
                  <img src="@/assets/images/home/tj-icon-3.png" alt="">
                </div>
                <h2>{{ myInfo.assignment.customerCount }}</h2>
              </div>
              <div class="iq-map text-warning font-size-32">
                <i class="ri-bar-chart-grouped-line"></i>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3" sm="6">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <h6>{{ $t("dashboard1.card4") }}</h6>
              <!-- <span class="iq-icon"><i class="ri-information-fill"></i></span> -->
            </div>
            <div
              class="
                iq-customer-box
                d-flex
                align-items-center
                justify-content-between
                mt-3
              "
            >
              <div class="d-flex align-items-center">
                <div class="rounded-circle iq-card-icon iq-bg-info mr-2">
                  <!-- <i class="ri-refund-line"></i> -->
                  <!-- <i class="ri-history-line"></i> -->
                  <!-- <i class="ri-history-fill"></i> -->
                  <img src="@/assets/images/home/tj-icon-4.png" alt="">
                </div>
                <h2>{{ myInfo.assignment.duration2 }}</h2>
              </div>
              <div class="iq-map text-info font-size-32">
                <i class="ri-bar-chart-grouped-line"></i>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8" v-for="(item, index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h6>{{ item.title }}</h6>
          </template>
          <div class="myCharts" style="width: 90%; height: 360px"></div>
          <!-- <template v-slot:body>
            <ApexChart :element="item.type" :chartOption="item.bodyData" />
          </template> -->
        </iq-card>
      </b-col>
      <b-col md="4">
        <iq-card body-class="smaill-calender-home">
          <div class="calendar-box">
            <div class="head-date">
              <i class="el-icon-arrow-left" @click="lastMonth"></i>
              <p class="month-p">{{Number($getDate(1,calendarDate).split('-')[1])}}月</p>
              <el-date-picker
                v-model="calendarDate"
                :clearable="false"
                @change="selectYear"
                type="year"
                placeholder="选择年">
              </el-date-picker>
              <i class="el-icon-arrow-right" @click="nextMonth"></i>
            </div>
            <el-calendar v-model="calendarDate" :first-day-of-week="7">
              <template
                slot="dateCell"
                slot-scope="{data}">
                  <!-- :class="data.day == '2022-09-24' || data.day == '2022-09-25' ? 'is-p':''" -->
                <div
                  @mouseover="mouseOver(data.day)"
                  @mouseleave="mouseLeave">
                  {{ data.day.split('-').slice(2).join('-') }}
                  <!-- <div
                    v-if="(isDate == '2022-09-24' && data.day == '2022-09-24') || (isDate == '2022-09-25' && data.day == '2022-09-25')">
                    第四届劳动法律师大会
                  </div> -->
                </div>
              </template>
            </el-calendar>
          </div>
          <!-- <template v-slot:body>
            <template v-if="lang.value == 'ar'">
              <flat-pickr :config="arConfig" value="" class="d-none" />
            </template>
            <template v-else>
              <flat-pickr :config="config" value="" class="d-none" />
            </template>
          </template> -->
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-row>
          <b-col md="6">
            <iq-card body-class="p-0">
              <template v-slot:headerTitle>
                <h6 class="card-title">{{ $t("dashboard1.exchangeRates") }}</h6>
              </template>
              <template v-slot:body>
                <div class="placeholder">
                  <div class="blank-box"></div>
                  <ApexChart element="home-chart-01" :chartOption="chart2"></ApexChart>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h6 class="card-title">最新订单</h6>
              </template>
              <!-- <template v-slot:body> -->
                <div class="order-box">
                  <el-row class="head-row">
                    <el-col :span="5">用户</el-col>
                    <el-col :span="8" title="服务名称">服务名称</el-col>
                    <el-col :span="7" title="服务时长">服务时长</el-col>
                    <el-col :span="4">单价</el-col>
                  </el-row>
                  <div class="scroll-box">
                    <el-row v-for="serveItem in serveList"
                          :key="serveItem.id">
                      <el-col :span="5" :title="serveItem.customerName">{{ serveItem.customerName }}</el-col>
                      <el-col :span="8" :title="serveItem.name">{{ serveItem.name }}</el-col>
                      <el-col :span="7" :title="`${serveItem.hour2}：${serveItem.minutes2}：${serveItem.seconds2}`">
                        {{ serveItem.hour2 }} ： {{ serveItem.minutes2 }} ：
                        {{ serveItem.seconds2 }}
                      </el-col>
                      <el-col :span="4" :title="`${serveItem.univalence}元/时`">{{ serveItem.univalence }}元/时</el-col>
                    </el-row>
                  </div>
                </div>
                <!-- <table class="table mb-0 table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" width="20%">用户</th>
                      <th scope="col" width="35%">服务名称</th>
                      <th scope="col" width="20%">服务时长</th>
                      <th scope="col" width="25%">单价</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="serve-tr"
                      v-for="serveItem in serveList"
                      :key="serveItem.id"
                    >
                      <td class="td1">{{ serveItem.customerName }}</td>
                      <td class="td2">{{ serveItem.name }}</td>
                      <td class="td3">
                        {{ serveItem.hour2 }} ： {{ serveItem.minutes2 }} ：
                        {{ serveItem.seconds2 }}
                      </td>
                      <td class="td4">{{ serveItem.univalence }}元/时</td>
                    </tr>
                  </tbody>
                </table> -->
              <!-- </template> -->
            </iq-card>
          </b-col>
          <b-col md="6">
            <iq-card body-class="p-0">
              <template v-slot:headerTitle>
                <h6 class="card-title">{{ $t("dashboard1.lastCosts") }}</h6>
              </template>
              <template v-slot:body>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <ul class="userTaskListUI">
                    <li v-for="item in userTaskList" :key="item.id">
                      <img :src="item.userHerder" alt="" />
                      <div class="userTaskListLI">
                        <p :title="item.userName ">{{ item.userName }}</p>
                        <span :title="item.userCentent">{{ item.userCentent }}</span>
                        <!-- <Proorder-boxhome-chart-03gressbar :value="item.progressbar" /> -->
                        <!-- <Progressbar color="bg-primary" /> -->
                      </div>
                      <p class="usreTaskTime" :style="`color: ${item.color};`">{{ item.progressbar }}小时</p>
                    </li>
                  </ul>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" v-if="chart3.series.length > 0">
            <iq-card body-class="p-0 position-relative">
              <template v-slot:headerTitle>
                <h6 class="card-title">{{ $t("dashboard1.efficiency") }}</h6>
              </template>
              <template v-slot:body>
                <ApexChart element="home-chart-03" :chartOption="chart3"></ApexChart>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <iq-card class="client-map" style="padding: 0 20px; margin-top: -35px">
          <template v-slot:headerTitle style="width: 100%">
            <h6 class="card-title">{{ $t("dashboard1.paymentHistory") }}</h6>
          </template>
          <template v-slot:body>
          <client-map
            class="map"
            v-if="showMap"
            :data="chinaMapData"
            @setKHNum="setKHNum"
            :info="regionStatisticsDataAll">
          </client-map>
        </template>
          <template>
            <el-row class="row-head">
              <el-col :span="10">地区</el-col>
              <el-col :span="7">客户量</el-col>
              <el-col :span="7">服务数量</el-col>
            </el-row>
            <el-row class="row-box">
                <div class="row-div" v-for="region in regionStatisticsData" :key="region.id">
                  <el-col :span="10">{{ region.adderss }}</el-col>
                  <el-col :span="7">{{ region.userData }}</el-col>
                  <el-col :span="7">{{ region.serviceQuantity }}</el-col>
                </div>
              </el-row>
            <!-- <table class="table mb-0 table-borderless">
              <thead>
                <tr>
                  <th scope="col" width="40%" style="height: 50px">地区</th>
                  <th scope="col" width="30%" style="height: 50px">客户量</th>
                  <th scope="col" width="30%" style="height: 50px">服务数量</th>
                </tr>
              </thead>
              <div class="row-box">
                <div class="row-div" v-for="region in regionStatisticsData" :key="region.id">
                  <p width="40%">{{ region.adderss }}</p>
                  <p width="30%">{{ region.userData }}</p>
                  <p width="30%">{{ region.serviceQuantity }}</p>
                </div>
              </div>
            </table> -->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../config/pluginInit";
// import AmChart from "../../components/core/charts/AmChart";
// import ApexChart from "../../components/core/charts/ApexChart";
import { mapGetters } from "vuex";
import { english } from "flatpickr/dist/l10n/default";
import { Arabic } from "flatpickr/dist/l10n/ar";
// const AmChart = () => import('../../components/core/charts/AmChart')
import {
  getDashboard,
  getWeekDuration,
  getCustomerRanking,
  getCustomerDistribution,
  getServiceLinear,
} from "@/FackApi/api/index.js";
import { getServicerecordList, getProjectList } from "@/FackApi/api/project.js";
import { Loading } from "element-ui";
// import { AxisTick } from "@amcharts/amcharts4/charts";
import moment from 'moment';
// import ClientMap from './clientMap2.vue'
import * as echarts from 'echarts';

export default {
  name: "Dashboard1",
  // AmChart,
  components: {
    // ApexChart, ClientMap
    ApexChart: ()=> import('../../components/core/charts/ApexChart'),
    ClientMap: ()=> import('./clientMap2.vue'),
  },
  // components: { ApexChart },
  mounted() {
    core.index();
    // this.initMap()
  },
  watch: {
    rtl: function (value) {
      this.options.rtl = this.rtl;
    },
  },
  computed: {
    ...mapGetters({
      rtl: "Setting/rtlModeState",
      lang: "Setting/langState",
    }),
  },
  data() {
    return {
      calendarDate: new Date(),
      isDate: '', // 鼠标移入日历的日期
      userTaskList: [],
      // 地区、客户、服务数量
      regionStatisticsDataAll: [],
      regionStatisticsData: [],
      items: [
        {
          client: "Ira Membrit",
          date: "18/10/2019",
          invoice: "20156",
          amount: "1500",
          status: { name: "paid", color: "success" },
          action: "Copy",
        },
        {
          client: "Pete Sariya",
          date: "26/10/2019",
          invoice: "7859",
          amount: "2000",
          status: { name: "paid", color: "success" },
          action: "Send email",
        },
        {
          client: "Cliff Hanger",
          date: "18/11/2019",
          invoice: "6396",
          amount: "2500",
          status: { name: "past due", color: "danger" },
          action: "Before Date",
        },
        {
          client: "Terry Aki",
          date: "14/12/2019",
          invoice: "7854",
          amount: "5000",
          status: { name: "paid", color: "success" },
          action: "Copy",
        },
        {
          client: "Anna Mull",
          date: "24/12/2019",
          invoice: "568569",
          amount: "10000",
          status: { name: "paid", color: "success" },
          action: "Send email",
        },
      ],
      monthlyInvocie: [
        {
          title: "Camelun ios",
          color: "success",
          amount: "12,434.00",
          paid_month: "17",
          total_month: "23",
        },
        {
          title: "React",
          color: "warning",
          amount: "12,434.00",
          paid_month: "17",
          total_month: "23",
        },
        {
          title: "Camelun ios",
          color: "success",
          amount: "12,434.00",
          paid_month: "17",
          total_month: "23",
        },
        {
          title: "Camelun ios",
          color: "danger",
          amount: "12,434.00",
          paid_month: "17",
          total_month: "23",
        },
      ],
      paymentHistory: [
        {
          title: "Deposit from ATL",
          amount: "- 1,470",
          icon: "ri-refresh-line",
          color: "secondary",
          date: "5 march, 18:33",
          currency: "USD",
        },
        {
          title: "Deposit PayPal",
          amount: "+ 2,220",
          icon: "ri-paypal-line",
          color: "primary",
          date: "5 march, 18:33",
          currency: "USD",
        },
        {
          title: "Deposit from ATL",
          amount: "+ 250",
          icon: "ri-check-line",
          color: "primary",
          date: "5 march, 18:33",
          currency: "USD",
        },
        {
          title: "Cancelled",
          amount: "0",
          icon: "ri-close-line",
          color: "info",
          date: "5 march, 18:33",
          currency: "USD",
        },
        {
          title: "Refund",
          amount: "- 500",
          icon: "ri-arrow-go-back-fill",
          color: "info",
          date: "5 march, 18:33",
          currency: "USD",
        },
        {
          title: "Deposit from ATL",
          amount: "- 1,470",
          icon: "ri-refresh-line",
          color: "secondary",
          date: "5 march, 18:33",
          currency: "USD",
        },
      ],
      chart1: {
        series: [
          {
            name: "Net Profit",
            data: [],
          },
          {
            name: "Revenue",
            data: [],
          },
        ],
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#6475c7", "#6ce6f4"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
          ],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
      chart2: {
        series: [
          {
            name: "服务时长（小时）",
            data: [], // 客户服务时长数据
          },
        ],
        chart: {
          height: 260,
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        colors: ["#6475c7"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
      },
      darkChart2: {
        series: [
          {
            name: "Desktops",
            data: [],
          },
        ],
        chart: {
          height: 150,
          type: "line",
          foreColor: "#8c91b6",
          zoom: {
            enabled: false,
          },
        },
        colors: ["#6475c7"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr"],
        },
      },
      chart3: {
        series: [],
        chart: {
          width: 380,
          height: 180,
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["已完成", "进行中", "待进行"],
        colors: ["#8279F3", "#6BE6F4", "#FCC647"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 150,
        },
      },
      darkChart3: {
        series: [],
        chart: {
          width: 380,
          height: 180,
          foreColor: "#8c91b6",
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#6475c7", "#6ce6f4", "#a09e9e", "#fbc647"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 150,
        },
      },
      charts: [
        {
          title: "工作服务记录",
          type: "scatter",
          bodyData: {
            chart: {
              height: 350,
              type: "scatter",
              zoom: {
                enabled: true,
                type: "xy",
              },
            },

            series: [
              {
                symbolSize: 20,
                data: [],
                type: "scatter",
              },
              {
                symbolSize: 20,
                data: [],
                type: "scatter",
              },
            ],
            colors: ["#6475c7", "#ff66pp"],
            xaxis: {
              type: "category",
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yaxis: {
              tickAmount: 5,
            },
          },
        },
      ],
      options: {
        centeredSlides: false,
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 4000,
        },
        spaceBetween: 30,
        breakpoints: {
          320: {
            slidesPerView: 1,
            // spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            // spaceBetween: 15
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            // spaceBetween: 20
          },
          768: {
            slidesPerView: 1,
            // spaceBetween: 20
          },
          1024: {
            slidesPerView: 2,
            // spaceBetween: 20
          },
        },
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
      config: {
        dateFormat: "Y-m-d",
        inline: true,
        locale: english,
      },
      arConfig: {
        dateFormat: "Y-m-d",
        inline: true,
        locale: Arabic,
      },
      myInfo: {
        assignment: {},
      },
      serveList: [], // 最新订单服务数据
      showMap: false, // 显示地图
      chinaMapData: {
        highlight: {
          province: "北京",//高亮显示省份
        },
        target: {
          // name: "九寨沟",//聚焦的城市名称
          // coordinate: [100.301299,31.688516],
        },
        data: [
          //迁徙轨迹
          // { from: "河池", coordinate: [108.08526, 24.69293], count: 1 },
        ],
      },
    };
  },
  created() {
    // 第三步 因为是自定义的getUserList方法 所以需要在钩子函数中调用才行！
    // this.getAppForm()
    this.init();
  },
  methods: {
    // 接口请求方法
    // async getAppForm () {
    //   const res = await getApprovalForm({ id: 1 })
    //   if (res.code === 0) {
    //   // code == 0请求成功
    //   }
    // }
    // initMap() {
    //   this.map = new AMap.Map('container', {
    //     viewMode: '3D',
    //     // center: [116.397428, 39.90923],//中心点坐标
    //     zoom: 13,
    //     pitch:30,
    //   // }).catch(e => {
    //   //   console.log(e);
    //   })
    // }
    init() {
      this.getDashboardInfo();
      this.getWeekDurationInfo();
      this.getCustomerRankingInfo();
      this.getServicerecordListInfo();
      this.getTaskListInfo();
      this.getCustomerDistributionInfo();
      this.getServiceLinearInfo();
    },
    // 获取任务统计
    async getDashboardInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getDashboard();
      // console.log(res);
      loadingInstance.close();
      if (res.code == 0) {
        this.myInfo.assignment = res.item;
        let theTime = res.item.duration;
        let middle = 0;
        let hour = 0;
        if (theTime > 59) {
          middle = parseInt(theTime / 60);
          theTime = parseInt(theTime % 60);
          if(middle > 59) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
          }
        }
        this.myInfo.assignment.duration2 = `
          ${hour < 10 ? "0" + hour : hour}:
          ${middle < 10 ? "0" + middle : middle}:
          ${theTime < 10 ? "0" + theTime : theTime}
        `;
      }
    },
    // 获取本周服务时长
    async getWeekDurationInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getWeekDuration();
      // console.log(res,'服务时长');
      loadingInstance.close();
      if (res.code == 0) {
        // chart2.series[0].data
        this.chart2.series[0].data = [0, 0, 0, 0, 0, 0, 0];
        let data = res.items;
        data.map((item) => {
          // let h = ((item.duration / 60 / 60) % 24).toFixed(2);
          let theTime = item.duration;
          let middle = 0;
          let hour = 0;
          if (theTime > 59) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if(middle > 59) {
              hour = parseInt(middle / 60);
              middle = parseInt(middle % 60);
            }
          }
          // console.log(hour);
          hour = (hour + Number((middle / 60).toFixed(2))).toFixed(2);
          this.chart2.series[0].data[item.day - 1] = hour;
        });
      }
    },
    // 获取月度客户排行
    async getCustomerRankingInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getCustomerRanking();
      // console.log(res,'月度客户');
      loadingInstance.close();
      if (res.code == 0) {
        this.userTaskList = [];
        let data = res.items;
        let idxNum = 0;
        data.map((item, index) => {
          let idx = index + "";
          let len = idx.split("");
          idx = len[len.length - 1];
          // console.log(typeof idx);
          // let hour = ((item.duration / 60 / 60) % 24).toFixed(2);
          let theTime = item.duration;
          let middle = 0;
          let hour = 0;
          if (theTime > 59) {
            middle = parseInt(theTime / 60);
            theTime = parseInt(theTime % 60);
            if(middle > 59) {
              hour = parseInt(middle / 60);
              middle = parseInt(middle % 60);
            }
          }
          // console.log(hour);
          hour = (hour + Number((middle / 60).toFixed(2))).toFixed(2);
          // console.log(hour);return
          if (idxNum == 3) {
            idxNum = 0;
          }
          idxNum += 1;
          if (idx === "0") {
            this.userTaskList.push({
              userHerder: require(`../../assets/images/home/hammer-${idxNum}.png`),
              userName: item.customerName,
              userCentent: item.assignmentName,
              progressbar: hour,
              color: "#857cf4",
            });
          } else if (idx === "1") {
            this.userTaskList.push({
              userHerder: require(`../../assets/images/home/hammer-${idxNum}.png`),
              userName: item.customerName,
              userCentent: item.assignmentName,
              progressbar: hour,
              color: "#28a745",
            });
          } else if (idx === "2") {
            this.userTaskList.push({
              userHerder: require(`../../assets/images/home/hammer-${idxNum}.png`),
              userName: item.customerName,
              userCentent: item.assignmentName,
              progressbar: hour,
              color: "#18a2b8",
            });
          }
        });
      }
    },
    // 获取服务记录列表
    async getServicerecordListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `1/10`;
      let res = await getServicerecordList(params);
      loadingInstance.close();
      // console.log(res);
      if (res.code == 0) {
        this.serveList = res.items;
        this.serveList.map((item) => {
          // console.log(item);
          item.duration = item.hour * 60 + item.minutes;
          item.hour2 = item.hour < 10 ? `0${item.hour}` : item.hour;
          item.minutes2 = item.minutes < 10 ? `0${item.minutes}` : item.minutes;
          item.seconds2 = item.seconds < 10 ? `0${item.seconds}` : item.seconds;
        });
      }
    },
    // 获取项目列表
    async getTaskListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let info = "/1/99";
      let res = await getProjectList(info);
      // console.log(res);
      loadingInstance.close();
      if (res.code == 0) {
        this.chart3.series = [];
        let data = res.items;
        let num = 0;
        let num2 = 0;
        let num3 = 0;
        data.forEach((item) => {
          if (item.assignmentFinishedCount == 0) {
            item.statusName = "待进行";
            num++;
          } else if (
            0 < item.assignmentFinishedCount &&
            item.assignmentFinishedCount < item.assignmentCount
          ) {
            item.statusName = "进行中";
            num2++;
          } else {
            num3++;
            item.statusName = "已完成";
          }
        });
        this.$nextTick(() => {
          this.chart3.series = [num3, num2, num];
        });
      }
    },

    // 获取客户分布
    async getCustomerDistributionInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `1/10`;
      let res = await getCustomerDistribution(params);
      loadingInstance.close();
      // console.log(res,'客户分布');
      if (res.code == 0) {
        // console.log(res.items);
        let newArr = [];
        res.items.map(item => {
          if (newArr.length == 0) {
            newArr.push(
              {
                adderss: item.province,
                userData: item.customerCount,
                serviceQuantity: item.serviceCount,
                cityList: [
                  {
                    adderss: item.city,
                    userData: item.customerCount,
                    serviceQuantity: item.serviceCount,
                  }
                ],
              }
            )
          } else {
            let _findIndex = newArr.findIndex((item2,index)=>{
              if (!item2.adderss) return
              return item2.adderss.includes(item.province);
            })
            if (_findIndex === -1) {
              newArr.push(
                {
                  adderss: item.province,
                  userData: item.customerCount,
                  serviceQuantity: item.serviceCount,
                  cityList: [
                    {
                      adderss: item.city,
                      userData: item.customerCount,
                      serviceQuantity: item.serviceCount,
                    }
                  ],
                }
              )
            } else {
              newArr[_findIndex].userData += item.customerCount;
              newArr[_findIndex].serviceQuantity += item.serviceCount;
              newArr[_findIndex].cityList.push(
                {
                  adderss: item.city,
                  userData: item.customerCount,
                  serviceQuantity: item.serviceCount,
                }
              )
            }
          }
        })
        this.regionStatisticsData = newArr;
        this.regionStatisticsDataAll = [].concat(newArr);
      }
      this.showMap = true;
    },
    setKHNum(arr) {
      this.regionStatisticsData = arr;
    },
    // 获取服务记录线性图
    async getServiceLinearInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let weekDate = this.$setWeekDate();
      let params = `/${weekDate[0].date}/${weekDate[6].date}`;
      let res = await getServiceLinear(params);
      let initDate = '2022-12-12'; // 固定日期 y轴时间跨天处理
      let echartDateList = []; // 图表x轴数据
      let echartLabel = []; // 图表底部标题
      let echartSeriesList = []; // 图表数据
      weekDate.map(item =>{
        echartDateList.push(item.showDate2)
      })
      loadingInstance.close();
      if (res.code == 0) {
        let data = res.items;
        // console.log(data);
        let colorArr = ["#008FFB", "#00E396", "#FFB01A", "#f14436", "#9791f5", "#db67ab"];
        data.map(item2 => {
          item2.serviceRecords.map(item3=>{
            // console.log(item3);
            let newDateArr = item2.date.split('-'); // 切割具体日期 取月份日期数据
            let m = Number(newDateArr[1]);
            let d = Number(newDateArr[2]);
            let monthData = `${m}月${d}日`;
            let timeArr = item3.time.split(':');
            let H = timeArr[0] === '00' ? '00' : Number(timeArr[0]) < 10 ? `0${Number(timeArr[0])}` : Number(timeArr[0]);
            let M = timeArr[1] === '00' ? '00' : Number(timeArr[1]) < 10 ? `0${Number(timeArr[1])}` : Number(timeArr[1]);
            let timeData = `${H}:${M}:00`;
            if (!echartLabel.includes(item3.assignmentTypeName)) {
              echartLabel.push(item3.assignmentTypeName)
            }
            // console.log(echartSeriesList,'-----------');
            if (echartSeriesList.length != 0) {
              // 判断底部标题是否在图表数据已存在 存在返索引
              let isIdx = echartSeriesList.findIndex(item4 => {
                // console.log(item4);
                if (!item4.name) return
                return item4.name.includes(item3.assignmentTypeName);
              })
              if (isIdx === -1) {
                echartSeriesList.push(
                  {
                    name: item3.assignmentTypeName,
                    type: "scatter",
                    itemStyle: {
                      focus: "series",
                      normal: {
                        color: colorArr[echartSeriesList.length],
                      },
                    },
                    data: [[monthData, `${initDate} ${timeData}`],],
                  }
                )
              } else {
                echartSeriesList[isIdx].data.push(
                  [monthData, `${initDate} ${timeData}`]
                )
              }
            } else {
              echartSeriesList.push(
                {
                  name: item3.assignmentTypeName,
                  type: "scatter",
                  
                  itemStyle: {
                      focus: "series",
                      normal: {
                        color: colorArr[echartSeriesList.length],
                      },
                    },
                  data: [[monthData, `${initDate} ${timeData}`],],
                }
              )
            }
          })
        })
        // console.log(echartSeriesList);
        // return
        const myCharts = echarts.init(
          document.querySelector(".myCharts")
        );
        let options = {
          grid: {
            x: 50,
            y: 10,
            x2: 10,
            y2: 90,
          },
          tooltip: {
            // 鼠标悬浮
            formatter: function(params){
              let showDate = (params.data[1].split(' '))[1]
                res= '<div><span>服务时间：</span>'+showDate+'</div>'
                return res
            }
          },
          xAxis: {
            splitLine: { show: false },
            type: "category",
            data: echartDateList,
          },
          yAxis: {
            type: "time",
            min: `${initDate} 00:00:00`,
            max: `${initDate} 23:59:59`,
            axisLabel: {
              formatter: function(value) {
                return (moment(value).format('YYYY-MM-DD HH:mm')).substr(11,20);
              }
            },
            splitLine: { show: false },
            axisLine: { show: false },
          },
          legend: {
            bottom: 10,
            left: "center",
            data: echartLabel,
          },
          colors: ["#008FFB", "#00E396", "#FFB01A", "#f14436", "#9791f5", "#db67ab"],
          series: echartSeriesList,
        };
        myCharts.setOption(options, true);
      }
    },
    // 鼠标移入日历
    mouseOver(date) {
      this.isDate = date;
    },
    // 鼠标移出日历
    mouseLeave() {
      this.isDate = '';
    },
    // 上一个月
    lastMonth() {
      let newDate = this.$getDate(1,this.calendarDate).split('-');
      let year = Number(newDate[0]);
      let month = Number(newDate[1]);
      if (month == 1) {
        year -= 1;
        month = 12;
      } else {
        month -= 1;
      }
      month = month < 10 ? `0${month}` : month;
      // this.calendarDate = new Date(`${year}-${month}-01`);
      if (`${year}-${month}` == this.$getDate(3)) {
        this.calendarDate = new Date();
      } else {
        this.calendarDate = new Date(`${year}-${month}-01`);
      }
      // console.log(date2);
    },
    // 下一个月
    nextMonth() {
      let newDate = this.$getDate(1,this.calendarDate).split('-');
      let year = Number(newDate[0]);
      let month = Number(newDate[1]);
      if (month == 12) {
        year += 1;
        month = 1;
      } else {
        month += 1;
      }
      month = month < 10 ? `0${month}` : month;
      // this.calendarDate = new Date(`${year}-${month}-01`);
      if (`${year}-${month}` == this.$getDate(3)) {
        this.calendarDate = new Date();
      } else {
        this.calendarDate = new Date(`${year}-${month}-01`);
      }
    },
    // 选择年
    selectYear() {
      let newDate = this.$getDate(1,this.calendarDate).split('-')[0];
      let newDate2 = this.$getDate(1).split('-')[0];
      if (newDate == newDate2) {
        this.calendarDate = new Date();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userTaskListUI {
  // 客户任务处理排行
  padding: 0 20px;
  min-height: 200px;
  li {
    width: 100%;
    position: relative;
    padding: 15px 0;
    float: left;
    border-bottom: 1px solid #e7e7e7;
    img {
      width: 44px;
      height: 44px;
    }
    .userTaskListLI {
      position: absolute;
      left: 90px;
      top: 10px;
      right: 60px;
      color: #606266;
      font-size: 16px;
      p {
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span {
        font-size: 14px;
        color: #a09e9e;
        display: block;
        margin-bottom: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .usreTaskTime {
      font-size: 16px;
      font-weight: bold;
      color: #837af3;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
  li:last-of-type {
    border-bottom: none;
  }
}
.position-relative {
  #home-chart-03 {
    height: 228px;
  }
}
/deep/ .serve-tr {
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .td1 {
    width: 100px !important;
  }
  .td2 {
    width: 30%;
  }
  .td3 {
    width: 26%;
  }
  .td4 {
    width: 10%;
  }
}
.apexcharts-canvas {
  width: 100% !important;
}
.client-map {
  // min-height: 450px;
  .map {
    height: 300px !important;
  }
  .el-col {
    color: #333;
  }
  .row-head {
        margin-top: 10px;
        font-weight: 600;
      }
    .row-box {
      height: 250px;
      overflow-y: auto;
      .row-div {
        display: flex;
        .el-col {
          line-height: 30px;
        }
      }
    }
  // /deep/ .table-borderless {
  //   min-height: 290px;
  //   width: 100%;
  // }
}
.order-box {
  padding: 0 20px;
  .el-row {
    .el-col {
      height: 40px;
      color: #333;
      // font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .head-row {
    .el-col {
      font-weight: 600;
    }
  }
  .scroll-box {
    height: 236px;
    overflow-y: auto;
  }
}
.myCharts {
  padding-left: 30px;
}
.cake-box {
  height: 330px;
}
h6 {
  font-weight: 600;
}
.calendar-box {
  position: relative;
  padding: 10px;
  overflow: hidden;
  .head-date {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 14px 14px 0 0;
    z-index: 9;
    i {
      font-size: 24px;
      color: #333;
      cursor:pointer;
    }
    .month-p {
      margin-left: 20px;
      color: #333;
    }
    .el-date-editor {
      width: 100px;
      margin-right: 20px;
      cursor:pointer;
    }
  }
  .el-calendar {
    margin-top: -18px;
    overflow-y: auto;
    /deep/ .el-calendar__body {
      padding: 12px 70px;
      thead {
        th {
          text-align: center;
        }
      }
      tbody {
        tr {
          td {
            border: none;
            .el-calendar-day {
              height: 40px;
              text-align: center;
              .is-p {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: red;
                color: #fff;
                border-radius: 4px;
                div {
                  position: absolute;
                  top: 30px;
                  left: 50%;
                  transform: translate(-50%, 0);
                  width: 130px;
                  max-height: 300px;
                  overflow-y: auto;
                  border-radius: 10px;
                  color: #333;
                  text-align: left;
                  padding: 5px;
                  background-color: #fff;
                  box-shadow: 5px 5px 5px 2px rgba(181, 181, 181, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}
  .placeholder {
    position: relative;
    // width: 100%;
    // height: 30px;
    // background-color: #fff;
    .blank-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background-color: #fff;
      z-index: 99;
    }
  }
</style>
<style>
.iq-card-body {
  flex: unset;
}
</style>
